export const DESKTOP_MIN_WIDTH = '1700px';
export const LAPTOP_MIN_WIDTH = '1160px';
export const TABLET_MIN_WIDTH = '800px';
export const MOBILE_MIN_WIDTH = '260px';

export const BROWSER_DEVICES = {
    DESKTOP: 'desktop',
    LAPTOP: 'laptop',
    TABLET: 'tablet',
    MOBILE: 'mobile',
}