import {GET_LIST_ANNOUCEMENT, READ_ANNOUNCEMENT} from "../../actions/type";

const initialState = {
    listAnnouncement: [
        {id: '1', title: 'Welcome to Lotto', content: 'Welcome to Lotto! Welcome to Lotto! Welcome to Lotto!', createdDate: '20/12/1996', isRead: false, createdBy: 'System'},
        {id: '2',title: 'Donna test announcement', content: 'Welcome to Donna test announcement! Welcome to Donna test announcement! Welcome to Lotto!', createdDate: '20/12/1997', isRead: true, createdBy: 'Donna'}
    ],
    isUpdate: false,
    notifyContent: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LIST_ANNOUCEMENT:
            return {
                ...state,
                listAnnouncement: payload?.listAnnouncement,
                notifyContent: payload?.msg
            }
        case READ_ANNOUNCEMENT:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
