import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;