import React, {useEffect} from "react";
import Snackbar from "@mui/material/Snackbar";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { closeAlert } from "actions/alert";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import "../../assets/color.css";
import { useTranslation } from "react-i18next";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    root: {
        "& .MuiSnackbarContent-root": {
            display: "block",
        },
        "& .MuiAlert-root" : {
            backgroundColor: "var(--color47)",
            borderRadius: "25px",
            minWidth: "350px",
            "& .MuiAlert-message" : {
                letterSpacing: "0.05em",
                fontSize: '14px',
                fontWeight: '400',
            }
        }
    },
});

function AlertFail({ closeAlert, msg, status }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(status);
    const history = useHistory();
    const {t} = useTranslation();

    useEffect(() => {
        return history.listen((location) => {
            setOpen(false);
            closeAlert();
        })
    },[history])
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        closeAlert();
    };

    return (
        <div>
            <Snackbar
                severity="error"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                className={classes.root}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    <Typography variant="subtitle2">{t(msg)}</Typography>
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = (state) => ({
    status: state.alert.errorStatus,
    msg: state.alert.msg
});

export default connect(mapStateToProps, { closeAlert })(AlertFail);