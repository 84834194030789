export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_FAIL="LOGIN_FAIL";
export const USER_LOADED="USER_LOADED";
export const AUTH_ERROR="AUTH_ERROR";
export const LOGOUT="LOGOUT";
export const CHANGE_LOGOUT_STATUS="CHANGE_LOGOUT_STATUS";
export const SET_FIRST_LOGIN="SET_FIRST_LOGIN";
export const FLAG_FORGOT_PASSWORD_SUCCESS="FLAG_FORGOT_PASSWORD_SUCCESS";
export const FLAG_RESET_PASSWORD_SUCCESS="FLAG_RESET_PASSWORD_SUCCESS";
export const VERIFY_TOKEN_RESET_PASSWORD_SUCCESS="VERIFY_TOKEN_RESET_PASSWORD_SUCCESS";
export const FLAG_VERIFY="FLAG_VERIFY";
export const FLAG_MAIL_EXPIRED="FLAG_MAIL_EXPIRED";
export const CHANGE_LANGUAGE="CHANGE_LANGUAGE";
export const TOKEN_ERROR="TOKEN_ERROR";
export const TOKEN_EXPIRED="TOKEN_EXPIRED";
export const CHANGE_PASSWORD="CHANGE_PASSWORD";

export const INTERNAL_ERROR="INTERNAL_ERROR";
export const TOGGLE_LEFT_SIDE_BAR="TOGGLE_LEFT_SIDE_BAR";
export const SELECTED_LIST_ITEM="SELECTED_LIST_ITEM";
export const SELECTED_GAME="SELECTED_GAME";


// RESULT

export const GET_LIST_DRAW_DATE="GET_LIST_DRAW_DATE";
export const GET_RESULT_BY_DATE="GET_RESULT_BY_DATE";
export const GET_RESULT_BY_NUMBER="GET_RESULT_BY_NUMBER";
export const GET_GUIDE_PAYOUT="GET_GUIDE_PAYOUT";
// ANNOUNCEMENT
export const GET_LIST_ANNOUCEMENT="GET_LIST_ANNOUCEMENT";
export const READ_ANNOUNCEMENT="READ_ANNOUNCEMENT";
// ACCOUNT
export const UPDATE_PHONE_PROFILE="UPDATE_PHONE_PROFILE";
export const UPDATE_NICKNAME_PROFILE="UPDATE_NICKNAME_PROFILE";
export const GET_LIST_SUB_ACCOUNT="GET_LIST_SUB_ACCOUNT";
export const GET_LIST_ACCOUNT_OPTION="GET_LIST_ACCOUNT_OPTION";
export const CREATE_SUB_ACCOUNT="CREATE_SUB_ACCOUNT";
export const EDIT_SUB_ACCOUNT="EDIT_SUB_ACCOUNT";
export const CHECK_USERID_IS_AVAILABLE="CHECK_USERID_IS_AVAILABLE";
export const RESET_CHECK_STATUS="RESET_CHECK_STATUS";
export const GET_SUB_ACCOUNT_DETAIL_BY_ID="GET_SUB_ACCOUNT_DETAIL_BY_ID";
// DOWNLINE
export const GET_LIST_AGENT="GET_LIST_AGENT";
export const GET_LIST_MEMBER="GET_LIST_MEMBER";
export const GET_AGENT_DETAIL="GET_AGENT_DETAIL";
export const GET_MEMBER_DETAIL="GET_MEMBER_DETAIL";
export const GENERATE_USER_ID="GENERATE_USER_ID";
export const GET_GAME_SETTING_BY_AGENT_ID="GET_GAME_SETTING_BY_AGENT_ID";
export const GET_LIST_DOWNLINE_OPTION="GET_LIST_DOWNLINE_OPTION";
export const WITHDRAW_MONEY="WITHDRAW_MONEY"
export const DEPOSIT_MONEY="DEPOSIT_MONEY"

export const ADD_AGENT_DOWNLINE="ADD_AGENT_DOWNLINE";
export const UPDATE_AGENT_DOWNLINE="UPDATE_AGENT_DOWNLINE";
export const UPDATE_AGENT_DOWNLINE_SETTING="UPDATE_AGENT_DOWNLINE_SETTING";
export const UPDATE_PHONE_AGENT_DOWNLINE="UPDATE_PHONE_AGENT_DOWNLINE";
export const UPDATE_AGENT_COPY_SETTING_FROM="UPDATE_AGENT_COPY_SETTING_FROM";
export const UPDATE_AGENT_COPY_SETTING_TO="UPDATE_AGENT_COPY_SETTING_TO";
export const UPDATE_AGENT_DOWNLINE_CREDIT="UPDATE_AGENT_DOWNLINE_CREDIT";
export const UPDATE_AGENT_DOWNLINE_BALANCE="UPDATE_AGENT_DOWNLINE_BALANCE";
export const UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE="UPDATE_AGENT_DOWNLINE_AUTO_RESET_BALANCE";

export const ADD_MEMBER_DOWNLINE="ADD_MEMBER_DOWNLINE";
export const UPDATE_MEMBER_DOWNLINE="UPDATE_MEMBER_DOWNLINE";
export const UPDATE_PHONE_MEMBER_DOWNLINE="UPDATE_PHONE_MEMBER_DOWNLINE";
export const UPDATE_MEMBER_DOWNLINE_CREDIT="UPDATE_MEMBER_DOWNLINE_CREDIT";
export const UPDATE_MEMBER_DOWNLINE_BALANCE="UPDATE_MEMBER_DOWNLINE_BALANCE";

// Alert
export const EDIT_ALERT="EDIT_ALERT";
export const CREATE_ALERT="CREATE_ALERT";
export const ERROR_ALERT="ERROR_ALERT";
export const DELETE_ALERT="DELETE_ALERT";
export const CLOSE_ALERT="CLOSE_ALERT";

// BETTING
export const GET_DRAW_DATE="GET_DRAW_DATE";
export const GET_CREDIT_INFO="GET_CREDIT_INFO";
export const CREATE_SIMPLE_BET="CREATE_SIMPLE_BET"
export const CREATE_MASS_BET="CREATE_MASS_BET"
export const CREATE_MASS_BET_TWO="CREATE_MASS_BET_TWO"
export const BETTING_HISTORY="BETTING_HISTORY"
export const CANCEL_BETTING="CANCEL_BETTING"
export const GET_LIST_DOWNLINE="GET_LIST_DOWNLINE";

export const GET_DRAW_DAY="GET_DRAW_DAY";

// ONLINE LIST
export const GET_LIST_ONLINE_LIST="GET_LIST_ONLINE_LIST"
export const DELETE_SAVED_BET="DELETE_SAVED_BET"
export const DELETE_SAVED_BET_TOTO="DELETE_SAVED_BET_TOTO"
export const HANDLE_SAVE_BET="HANDLE_SAVE_BET"
export const HANDLE_SAVE_BET_TOTO="HANDLE_SAVE_BET_TOTO"
export const GET_SAVED_BET="GET_SAVED_BET"
export const GET_SAVED_BET_TOTO="GET_SAVED_BET_TOTO"
export const DELETE_FIXED_BET="DELETE_FIXED_BET"
export const DELETE_FIXED_BET_TOTO="DELETE_FIXED_BET_TOTO"
export const SUBMIT_SAVE_BET="SUBMIT_SAVE_BET"
export const SUBMIT_SAVE_BET_TOTO="SUBMIT_SAVE_BET_TOTO"
export const GET_LIST_FIXED_BET="GET_LIST_FIXED_BET"
export const GET_LIST_FIXED_BET_TOTO="GET_LIST_FIXED_BET_TOTO"

export const SAVE_LIST_FIXED_BET="SAVE_LIST_FIXED_BET"
export const SAVE_LIST_FIXED_BET_TOTO="SAVE_LIST_FIXED_BET_TOTO"

export const TURN_OFF_BET="TURN_OFF_BET"
export const TURN_OFF_BET_TOTO="TURN_OFF_BET_TOTO"
export const TURN_ON_BET="TURN_ON_BET"
export const TURN_ON_BET_TOTO="TURN_ON_BET_TOTO"

export const SUBMIT_WILD_CARD_BET="SUBMIT_WILD_CARD_BET"


// LOGIN LOG
export const LOGIN_HISTORY_LOG = "LOGIN_HISTORY_LOG"
export const TRANSACTION_HISTORY_LOG ="TRANSACTION_HISTORY_LOG"

export const GET_LEFT_MENU ="GET_LEFT_MENU"
export const START_GET_RESULT_BY_DATE = "START_GET_RESULT_BY_DATE";
export const END_GET_RESULT_BY_DATE = "END_GET_RESULT_BY_DATE";
export const GET_DRAW_DATE_OF_WEEK = "GET_DRAW_DATE_OF_WEEK";
export const CREATE_ADVAN_BET_TOTO = "CREATE_ADVAN_BET_TOTO";
export const CREATE_MASS_BET_TOTO = "CREATE_MASS_BET_TOTO";
export const CREATE_ADDITIONAL_BET_TOTO = "CREATE_ADDITIONAL_BET_TOTO";
export const BETTING_HISTORY_TOTO = "BETTING_HISTORY_TOTO";
export const CANCEL_BETTING_TOTO = "CANCEL_BETTING_TOTO";
export const GET_LIST_TOTO_DRAW_DATE = "GET_LIST_TOTO_DRAW_DATE";

export const CREATE_SIMPLE_BET_TOTO = "CREATE_SIMPLE_BET_TOTO";

