import {useMediaQuery} from "@mui/material";
import {DESKTOP_MIN_WIDTH, LAPTOP_MIN_WIDTH, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH} from "../config/breakpoints.config";

export const GetDetectBrowserInfo = () => {
    return {
        isDesktop: useMediaQuery(`(min-width: ${DESKTOP_MIN_WIDTH})`),
        isLaptop: useMediaQuery(`(min-width: ${LAPTOP_MIN_WIDTH})`),
        isTablet: useMediaQuery(`(min-width: ${TABLET_MIN_WIDTH})`),
        isMobile: useMediaQuery(`(min-width: ${MOBILE_MIN_WIDTH})`)
    };
};
export const BREAK_POINT_FOR_LAPTOP = 1537;

export const ACTION_BTN_VARIANTS = {
    PRIMARY: "PRIMARY",
    OUTLINED: "OUTLINED"
};
export const ACTION_BTN_CLASSNAMES = {
    [ACTION_BTN_VARIANTS.PRIMARY]: 'primary-btn',
    [ACTION_BTN_VARIANTS.OUTLINED]: 'outline-btn',
};

export const EMPTY = '';
export const DATE_FORMAT = 'DD/MM/YYYY';

export const TYPE_NOT_EDIT= 'not_edit';
export const LIST_TYPE_OF_TOTO = {
    NORMAL: 'N',
    ROLL: 'R',
    ROLL_TWO: 'R2',
    ROLL_THREE: 'R3',
    ROLL_FOUR: 'R4',
    ROLL_FIVE: 'R5',
    ROLL_SEVEN: 'R7',
    TT: 'TT',
    T_TWO: 'T2',
    T_THREE: 'T3',
    STAR_TWO: '*2',
    STAR_THREE: '*3',
    TWO_STAR: '2*',
    THREE_STAR: '3*',
    FOUR_STAR: '4*'
}

export const GAME_CODE = {
    SG4D: 'SG4D',
    TOTO: 'TOTO'
}

export const DEFAULT_EMPTY_TEXT = "-";
export const REACT_API_LOCATION = `${window.location.protocol}//api-${window.location.host}`;