import {TRANSACTION_HISTORY_LOG} from "../../actions/type";


const initialState = {
    transactionLog: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TRANSACTION_HISTORY_LOG:
            return {
                ...state,
                transactionLog: payload
            }
        default:
            return state;
    }
}
