import { LOGIN_HISTORY_LOG } from "../../actions/type";

const initialState = {
    loginLog: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_HISTORY_LOG:
            return {
                ...state,
                loginLog: payload,
            };
        default:
            return state;
    }
}
