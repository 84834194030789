import {GET_LIST_ANNOUCEMENT, READ_ANNOUNCEMENT} from "../type";

import axios from "axios";
import {REACT_API_LOCATION} from "../../constants/constants";

export const getListAnnouncement = () => async (dispatch) =>{
    try {
        // Call API

        const res = await axios.get(`${REACT_API_LOCATION}/player-service/announcement`);
        let msg = '';
        res?.data?.data?.length>0 && res?.data?.data?.map((item) => {
            msg = item?.displaying ? msg.concat(`   ${item?.content}`) : msg;
        });
        const data = {
            listAnnouncement: res?.data?.data,
            msg: msg
        }
        dispatch({
            type:GET_LIST_ANNOUCEMENT,
            payload: data
        })
    } catch (error) {
        console.log(error)
    }
}
export const readAnnouncement = () => (dispatch) =>{
    try {
        // Call API

        dispatch({
            type: READ_ANNOUNCEMENT
        })
    } catch (error) {
        console.log(error)
    }
}