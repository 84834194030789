import {
    CHANGE_LANGUAGE
} from "../actions/type";

const initialState = {
    language: 'en'
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: payload
            };
        default:
            return state;
    }
}
