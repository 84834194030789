import {
    DELETE_SAVED_BET, DELETE_SAVED_BET_TOTO,
    GET_SAVED_BET, GET_SAVED_BET_TOTO,
    HANDLE_SAVE_BET, HANDLE_SAVE_BET_TOTO,
    SUBMIT_SAVE_BET, SUBMIT_SAVE_BET_TOTO
} from "../../actions/type";

const initialState = {
    listSavedBet: [],
    listSavedBetToto: [],
    isUpdate: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_SAVED_BET:
            return {
                ...state,
                listSavedBet: payload
            }
        case GET_SAVED_BET_TOTO:
            return {
                ...state,
                listSavedBetToto: payload
            }
        case SUBMIT_SAVE_BET:
        case SUBMIT_SAVE_BET_TOTO:
        case HANDLE_SAVE_BET:
        case HANDLE_SAVE_BET_TOTO:
        case DELETE_SAVED_BET:
        case DELETE_SAVED_BET_TOTO:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
