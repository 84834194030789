import {
    CREATE_ALERT,
    EDIT_ALERT,
    DELETE_ALERT,
    CLOSE_ALERT,
    ERROR_ALERT,
} from "actions/type";

const initialState = {
    status: false,
    errorStatus: false,
    msg: ""
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_ALERT:
        case EDIT_ALERT:
        case DELETE_ALERT:
            return {
                ...state,
                status: true,
                msg: payload
            }
        case ERROR_ALERT:
            return {
                ...state,
                status: true,
                errorStatus: true,
                msg: payload,
            };
        case CLOSE_ALERT:
            return {
                ...state,
                status: false,
                errorStatus: false,
                msg: "",
            };
        default:
            return state;
    }
}