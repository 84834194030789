import {getLocalToken, removeLocalToken} from "../config/globalStatement";
import axios from "axios";
import {store} from "../store";
import {AUTH_ERROR, INTERNAL_ERROR, TOKEN_ERROR, TOKEN_EXPIRED} from "../actions/type";
import {logout} from "../actions/auth";

const setAuthToken = async () => {
    const token = getLocalToken();
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        break;
                    case 401:
                        removeLocalToken();
                        logout();
                        store.dispatch({
                          type: TOKEN_EXPIRED,
                          payload: error.response.data,
                        });
                        break;
                    case 403:
                        store.dispatch({
                            type: TOKEN_ERROR,
                            payload: error.response.data
                        })
                        break;
                    case 500:
                        if(error?.response?.data?.msg === "validate jwt"){
                            store.dispatch({
                                type: AUTH_ERROR
                            })
                        } else {
                            store.dispatch({
                                type: INTERNAL_ERROR,
                                payload: error.response.data
                            })
                        }
                        break;
                }
            } else {
                if (
                    error.code === "ECONNABORTED" &&
                    error.message &&
                    error.message.indexOf("timeout") !== -1
                ) {
                    // request time out will be here
                    console.log("request time out will be here");
                } else {
                    // shut down api server
                    console.log("shut down api server");
                }
            }
            return Promise.reject(error);
        }
    );
}
export default setAuthToken;