import {CREATE_MASS_BET_TWO} from "../../actions/type";

const initialState = {
    isCreateMassbetTwo: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_MASS_BET_TWO:
            return {
                ...state,
                isCreateMassbetTwo: payload
            }
        default:
            return state;
    }
}
