import './App.css';
import React, {Suspense, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {persistor, store} from "./store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import "i18n";
import {keepAlive} from "./actions/auth";
import AppRouter from "./AppRouter";

const App = () => {

    useEffect(() => {
        const keepAliveInterval = setInterval(() => {
            store.getState().auth.token && store.dispatch(keepAlive())
        }, 5000);
        return () => clearInterval(keepAliveInterval);
    }, []);

    return (
        <Provider store={store}>
            <Suspense fallback={<div>Loading...</div>}>
                <PersistGate persistor={persistor}>
                    <Router>
                        <AppRouter/>
                    </Router>
                </PersistGate>
            </Suspense>
        </Provider>
    );
}

export default App;
