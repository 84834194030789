import { combineReducers } from "redux";
import auth from "./auth";
import language from "./language";
import common from "./common";
import alert from "./alert";
//persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import simpleBet from "./BettingManagement/simpleBet";
import result from "./Result/result";
import announcement from "./Announcement/announcement";
import wildCardBet from "./BettingManagement/wildCardBet";
import massBet from "./BettingManagement/massBet";
import massBetTwo from "./BettingManagement/massBetTwo";
import savedBet from "./BettingManagement/savedBet";
import fixedBet from "./BettingManagement/fixedBet";
import bettingHistory from "./BettingManagement/bettingHistory";
import loginHistory from "./ViewLog/loginHistory";
import transactionLog from "./ViewLog/transactionHistory";
import totoBettingHistory from "./BettingManagement/totoBettingHistory";
const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "language"
    ],
};

const appReducer = combineReducers({
    auth,
    language,
    common,
    alert,
    simpleBet,
    massBet,
    massBetTwo,
    savedBet,
    fixedBet,
    result,
    announcement,
    wildCardBet,
    bettingHistory,
    loginHistory,
    transactionLog,
    totoBettingHistory
});

const rootReducer = (state, action) => {
    if (action.type === "LOGIN_AS_SUCCESS" || action.type === "LOGOUT") {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);