import React from "react";
import { Route, Redirect } from "react-router-dom";
//Redux
import { connect } from "react-redux";

const PrivateRoute = ({
                          component: Component,
                          auth: { isAuthenticated, loading,  user },
                          env,
                          permit,
                          mainMenu,
                          ...rest
                      }) => {
    const checkPermission = () => {
        if(mainMenu==='account' && user?.level === 'PL'){
            return false;
        }
        if(user?.[permit] || permit === undefined || (permit === 'level' && (user?.level === 'AG' || user?.level === 'MA'))) {
          return true;
        }
        return false
    }

    return (
        <Route
            {...rest}
            render={(props) =>(
                    (!isAuthenticated && !loading) ? (
                        <Redirect to="/login" />
                    ) : checkPermission() ? (
                        <Component {...props} />
                    ) : null
                )
            }
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
