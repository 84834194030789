import { SUBMIT_WILD_CARD_BET} from "../../actions/type";

const initialState = {
    isUpdate: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SUBMIT_WILD_CARD_BET:
            return {
                ...state,
                isUpdate: !state.isUpdate
            }
        default:
            return state;
    }
}
