import React, { lazy, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import PrivateRoute from "./pages/GlobalManagement/routing/PrivateRoute";
import Layout from "./pages/GlobalManagement/layout/Layout";
import {getListMenu, loadUser} from "./actions/auth";
import {selectGame} from "./actions/common";

const Announcement = lazy(() => import('./pages/Announcement/Announcement'));
const Result = lazy(() => import('./pages/Result/Result'));
const NoMatch = lazy(() => import('./pages/GlobalManagement/routing/NoMatch'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const SignInPage = lazy(() => import('./pages/GlobalManagement/auth/Login'));
const SavedBet = lazy(() => import('./pages/BettingManagement/SavedBet/SavedBet'));
const WildCardBet = lazy(() => import('./pages/BettingManagement/WildcardBet/WildCardBet'));
const MassBetTwo = lazy(() => import('./pages/BettingManagement/MassBetTwo/MassBetTwo'));
const MassBet = lazy(() => import('./pages/BettingManagement/MassBet/MassBet'));
const SimpleBet = lazy(() => import('./pages/BettingManagement/SimpleBet/SimpleBet'));
const FixedBet = lazy(() => import('./pages/BettingManagement/FixedBet/FixedBet'));
const BettingHistory = lazy(() => import('./pages/BettingManagement/BettingHistory/BettingHistory'));
const LoginLog = lazy(() => import('./pages/LogManagement/LoginLog/LoginLog'));
const MainPage = lazy(() => import('./pages/GlobalManagement/mainPage/MainPage'));
const Loading = lazy(() => import('./pages/GlobalManagement/routing/Loading'));
const TotoProfile = lazy(() => import('./pages/Toto/MainManagement/Profile/Profile'));
const TotoResult = lazy(() => import('./pages/Toto/MainManagement/Result/Result'));
const TotoAnnouncement = lazy(() => import('./pages/Toto/MainManagement/Announcement/Announcement'));
const TotoSimpleBet = lazy(() => import('./pages/Toto/BettingManagement/SimpleBet/SimpleBet'))
const TotoAdvanBet = lazy(() => import('./pages/Toto/BettingManagement/AdvanBet/AdvanBet'))
const TotoMassBetTwo = lazy(() => import('./pages/Toto/BettingManagement/MassBetTwo/MassBetTwo'));
const TotoBetHistory = lazy(() => import('./pages/Toto/BettingManagement/BettingHistory/BettingHistory'));
const TotoMassBet = lazy(() => import('./pages/Toto/BettingManagement/MassBet/MassBet'));
const TotoSavedBet = lazy(() => import('./pages/Toto/BettingManagement/SavedBet/SavedBet'))
const TotoFixedBet = lazy(() => import('./pages/Toto/BettingManagement/FixedBet/FixedBet'))

const MenuComponent4DMap = {
    PROFILE: Profile,
    RESULT: Result,
    ANNOUNCEMENTS: Announcement,
    SIMPLE_BET: SimpleBet,
    WILDCARD_BET: WildCardBet,
    MASS_BET: MassBet,
    MASS_BET_2: MassBetTwo,
    SAVED_BET: SavedBet,
    FIXED_BET: FixedBet,
    BETTING_HISTORY: BettingHistory,
    LOGIN_LOGS: LoginLog,
};
const MenuComponentTotoMap = {
    PROFILE: TotoProfile,
    RESULT: TotoResult,
    ANNOUNCEMENTS: TotoAnnouncement,
    SIMPLE_BET: TotoSimpleBet,
    ADVAN_BET: TotoAdvanBet,
    ADDITIONAL_BET: TotoMassBetTwo,
    MASS_BET: TotoMassBet,
    SAVED_BET: TotoSavedBet,
    FIXED_BET: TotoFixedBet,
    BETTING_HISTORY: TotoBetHistory,
    LOGIN_LOGS: LoginLog,
};
const AppRouter = () => {

    const token = useSelector(state => state.auth?.token);
    const leftMenuList = useSelector(state => state.auth?.listLeftMenu);
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const gameCode = location?.pathname?.split('/')?.[1];
    useEffect(() => {
        setLoading(true);
        dispatch(selectGame(gameCode));
            if (token) {
                dispatch(loadUser());
                dispatch(getListMenu(gameCode));
            } else if (!window.location.href.includes('/login')) {
                window.location.href = '/login';
                setLoading(false);
            }
        }, [token, gameCode]
    );

    const memoizedArray = useMemo(() => {
        let newArr = [];
        const gameMenu = gameCode === '4d' ? MenuComponent4DMap: MenuComponentTotoMap;
        leftMenuList.map((leftMenu, index) => {
            const menuCode = leftMenu.menuCode;
            const MenuLayout = gameMenu[menuCode];
            const subMenu = leftMenu.subMenu;
            if(MenuLayout) {
                newArr =[...newArr, {key: `${menuCode}_${index}`,path: `/${gameCode}${leftMenu.menuLink}`, name: leftMenu.menuName, MenuLayout: MenuLayout}];

            }
            if (subMenu) {
                subMenu.map(sub => {
                    const SubLayout = gameMenu[sub.subMenuCode];
                    newArr =[...newArr, { key: `${menuCode}_${sub.subMenuCode}_${index}`, path: `/${gameCode}${sub.subMenuLink}`, name: sub.subMenuName, MenuLayout: SubLayout}]
                })
            }
        })
        setLoading(false)
        return newArr;
    }, [leftMenuList]);
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={token ? '/main' : '/login'}/>
            </Route>
            <Route exact path="/login" component={SignInPage}/>
            <PrivateRoute exact path="/main" component={Layout(MainPage, true)}/>
            {memoizedArray?.map(menu => (
                <PrivateRoute key={menu?.key} exact path={menu?.path} component={Layout(menu?.MenuLayout, menu?.MenuName)}/>
            ))}
            {loading &&
                <Route>
                    <Loading />
                </Route>
            }
            {!loading && leftMenuList?.length > 0 &&
                <Route>
                    <NoMatch/>
                </Route>
            }
        </Switch>
    );
}

export default AppRouter;
